const $win = $(window);
const $doc = $(document);
const $body = $('body');
const $root = $('html, body');

export {
	$win,
	$doc,
	$body,
	$root
};
