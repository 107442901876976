import { $win, $body } from '../utils/globals';
import { isIe } from '../utils/is-ms';
import { isMobile } from '../utils/is-mobile';

$body.toggleClass('is-ie', isIe);
$body.toggleClass('is-mobile', isMobile);

$win.on('load', () => {
	setTimeout(function() {
		$body.addClass('loaded');
	}, 200);
});
