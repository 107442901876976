const scrollToNextSection = (selector) => {
	$(selector).on('click', function(event) {
		const $parent = $(this).closest('section');
		const top = $parent.offset().top + $parent.height();
		const HEADER_HEIGHT = 0;

		$( 'html, body' ).animate({
			scrollTop: top - HEADER_HEIGHT
		}, 1000);

		event.preventDefault();
	});
}

export default scrollToNextSection;
