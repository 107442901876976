import scrollToNextSection from './utils/scroll-to-next-section';
import objectFit from './modules/object-fit';
import magnificPopupVideos from './modules/magnific-popup-videos';
import initMemberExpand from './modules/init-member-expand';
import mobileNavigation from './modules/mobile-navigation';

// Your includes goes here...
import './modules/scroll-to';
import './modules/body-classes';


// Object Fit fallback

objectFit();
scrollToNextSection('.js-scroll-next');
magnificPopupVideos('.js-video-popup');
initMemberExpand();
mobileNavigation();
