import { isIe } from '../utils/is-ms';

/**
 * Object Fit fallback
 *
 * @return {void}
 */
const objectFit = () => {
	if (isIe()){
		$('.js-image-fit').each((i, container) => {
			const $container = $(container);
			const $img = $container.find('img');
			const src = $img.attr('src') || $img.data('src');

			$container.css('background-image', `url(${src})`);
			$img.addClass('sr-only');
		});
	}
}

export default objectFit
