import { $win, $body } from '../utils/globals'
import { isMobile } from '../utils/is-mobile'

/**
 * Show more info about every block
 * @return {void}
*/
const mobileNavigation = () => {
	let $scrollPosition = null;
	const MOBILE_WIDTH = 1023;

	if (isMobile || $win.width() < MOBILE_WIDTH) {
		initMobileNavigation();
	}

	$('.js-nav-trigger').on('click', function(e){
		e.preventDefault();


		if (isMobile && $win.width() < MOBILE_WIDTH) {
			const isOpened = $body.is('.has-menu-opened');

			if (isOpened) {
				$body.removeClass('has-menu-opened');
				$body.removeClass('is-fixed');
				window.scrollTo(0, $scrollPosition)
				$scrollPosition = null;
				return
			} else {
				$scrollPosition = $(window).scrollTop();
				setTimeout(function() {
					$body.addClass('is-fixed');
				}, 400);
			}
		}

		$body.toggleClass('has-menu-opened');
	});

}

export default mobileNavigation

/**
 * Show sub menus on click
 * @return {void}
*/
function initMobileNavigation () {
	$('.menu-item-has-children > a').on('click', function(e){
		if (!$(this).parent().hasClass('js-show')) {
			e.preventDefault();

			$(this)
			.next()
			.slideDown()
			.parent()
			.addClass('js-show')
			.siblings()
			.removeClass('js-show')
			.find('.sub-menu')
			.slideUp()
			.find('.js-show')
			.removeClass('js-show');
		};
	});
}
