const initMemberExpand = () => {
	$('.js-expand-all').on('click', function(e){
		e.preventDefault();

		const $this = $(this);

		$this
			.parent()
			.hide();

		$this
			.closest('section')
			.find('.artists')
			.addClass('show-all');
	});
}
export default initMemberExpand
