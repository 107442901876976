import { $win, $body, $root } from '../utils/globals'

const winHash = window.location.hash;
const winHref = window.location.href;

$('.header .nav a').each(function () {
	const $anchor = $(this);
	const $anchorHref = $anchor.attr('href');
	const $anchorHash = this.hash;
	const cleanedUrl = cleanURL($anchorHref, $anchorHash);

	$anchor.on('click', function(event) {
		if (! isSamePage(cleanedUrl)) {
			return;
		};

		scrollByDataId($anchorHash);
	});
});

$win.load(function () {
	if ( winHash.length > 0 ) {
		$root.scrollTop(0, 0);

		setTimeout(function() {
			scrollByDataId(winHash);
		}, 500);
	}
});

/**
 * Scroll by given data-id.
 *
 * @param  {String} dataId
 * @return {Void}
 */
function scrollByDataId (dataId) {
	if (! dataId) {
		return false;
	};

	const $selector = $(`*[data-id="${dataId}"]`);

	if ($selector.length > 0) {
		$root.animate({
			scrollTop: $selector.offset().top
		}, 1000);
	};
}

/**
 * Check if the target is in the same page.
 *
 * @param  {String}  href
 * @return {Boolean}
 */
function isSamePage (url) {
	if (! url) {
		return;
	}

	const cleanedUrl = cleanURL(winHref, winHash);

	if (url === cleanedUrl) {
		return true;
	}

	return false;
}

/**
 * Clear page url.
 *
 * @param  {String} url
 * @param  {String} hash
 * @return {Boolean}
 */
function cleanURL( url, hash ) {
	if ( ! url ) {
		return false;
	};

	if (hash) {
		url = url.replace(hash,'');
	};

	return url;
}
