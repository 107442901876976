import 'magnific-popup';
/**
 * Init Magnific Popup
 *
 * @return {void}
 */
const magnificPopupVideos = (element = '.js-popup') => {
	const $element = $(element);

	if (! $element.length) {
		return false;
	}

	$element.magnificPopup({
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		fixedContentPos: true,
	});
};

export default magnificPopupVideos;
